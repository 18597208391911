<template>
  <div class="gradeClassManage">
    <div class="header">
      <el-button type="success"
                 @click="furtherEducation"
                 class="furtherEducation-box">一键升学
      </el-button>
      <term-selector placeholder="请选择学期"
                     @change="querySemesterChange"
                     v-model="queryYearId" />
      <!-- <el-select v-model="queryYearId"
                 @change="loadAllClass"
                 placeholder="请选择学期">
        <el-option v-for="semester in semesterList"
                   :key="semester.id"
                   :label="semester.name"
                   :value="semester.id"></el-option>
      </el-select> -->
    </div>
    <div v-for="grade in gradeList"
         :key="grade.id">
      <div class="grade-box">
        <div>
          <div>{{ grade.gname }}[{{ grade.classlist.length }}个班级]</div>
          <el-link @click="modifyGradeLeader(grade)">年级长-{{ grade.gmanager }}</el-link>
        </div>
      </div>
      <!-- 班级 -->
      <div class="class-box">
        <div v-for="cls in grade.classlist"
             :key="cls.id"
             @click="modifyClass(grade, cls)">
          <p class="cls-name">
            <span>{{ cls.cname }} </span>
            <span>[{{cls.stunum}}人]</span>
          </p>
          <p>班主任-{{ cls.headmanager }}</p>
          <p>副班主任-{{ cls.f_manager }}</p>
          <p>生活老师-{{ cls.smanager }}</p>
        </div>
      </div>
    </div>
    <!-- 表单 -->
    <el-dialog title="修改班级"
               :visible.sync="classVisible"
               width="600px">
      <el-form ref="addFrom"
               :model="classForm"
               label-width="80px"
               :rules="rules">
        <el-form-item label="所属年级"
                      prop="gname">
          <el-input v-model="classForm.gname"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="班级名称"
                      prop="cname">
          <el-input v-model="classForm.cname"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="班主任"
                      prop="headmanager">
          <el-select v-model="classForm.headmanager"
                     filterable
                     clearable
                     value-key="id">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="{ name: teacher.name, id: teacher.id }"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="副班主任"
                      prop="deputyHeadmanager">
          <el-select v-model="classForm.deputyHeadmanager"
                     filterable
                     clearable
                     value-key="id">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id "></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生活老师"
                      prop="smanager">
          <el-select v-model="classForm.smanager"
                     filterable
                     clearable
                     value-key="id">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value=" teacher.id "></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序标识"
                      prop="sort_index">
          <el-input v-model="classForm.sort_index"
                    disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="classVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addClassConfirm">确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="gradeLeaderVisible"
               title="修改年级长"
               width="600px">
      <el-form :model="gradeLeaderForm"
               ref="gradeLeaderForm"
               label-width="100px">
        <el-form-item label="年级长">
          <el-select v-model="gradeLeaderForm.managerid"
                     filterable>
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="gradeLeaderVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="gradeLeaderConfirm">确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TermSelector from "@/components/selectors/termSelector";
export default {
  name: "gradeClassManage",
  components: { TermSelector },
  data() {
    return {
      gradeList: [],
      classForm: {
        gname: "",
        gradeid: "",
        cname: "",
        sort_index: "",
        headmanager: null,
        deputyHeadmanager: "",
        smanager: "",
        id: "",
      },
      teacherList: [],
      gradeLeaderVisible: false,
      classVisible: false,
      gradeLeaderForm: { managerid: "", id: "" },
      rules: {
        gname: [
          {
            required: true,
          },
        ],
        cname: [
          {
            required: true,
            message: "请输入班级名称",
          },
        ],
        sort_index: [
          {
            required: true,
            message: "请输入排序标识",
          },
        ],
      },
      queryYearId: "",
      semesterList: JSON.parse(sessionStorage.getItem("semesterList")),
    };
  },
  created() {
    //   获取年级班级信息
    this.loadAllClass();
    this.loadAllTeacher();
    console.log(this.semesterList);
  },
  methods: {
    loadAllClass() {
      const that = this;
      this.$post("/media/queryAllclasses.do", { yearid: this.queryYearId })
        .then((res) => {
          that.gradeList = res.data;
        })
        .catch((err) => {
          that.$message.error(err.message.data);
        });
    },
    loadAllTeacher() {
      const that = this;
      this.$post("/media/queryAllManager.do", { is_no: 1 }) //查询身份是否校内，1是校内
        .then((res) => {
          that.teacherList = res.data;
        })
        .catch((err) => {
          that.$message.error(err.message.data);
        });
    },
    addClass(gname, id) {
      this.classVisible = true;
      this.$nextTick(() => {
        this.$refs["addFrom"].resetFields();
        this.classForm.gname = gname;
        this.classForm.gradeid = id;
        this.classForm.id = "";
      });
    },
    addClassConfirm() {
      const that = this;
      this.$refs["addFrom"].validate((valid) => {
        if (valid) {
          let apiUrl = "/media/updateClasses.do";
          let data = {
            id: this.classForm.id,
            // gradeid: this.classForm.gradeid,
            cname: this.classForm.cname,
            sort_index: this.classForm.sort_index,
            f_managerid: this.classForm.deputyHeadmanager,
            smanagerid: this.classForm.smanager,
          };
          if (this.classForm.headmanager) {
            data.managerid = this.classForm.headmanager.id; //班主任
            data.headmanager = this.classForm.headmanager.name;
          }
          console.log(this.classForm.headmanager.name);
          //   if (this.classForm.smanager) {
          //     data.smanagerid = this.classForm.smanager.id; //生活老师
          //     // data.smanager = this.classForm.smanager.name;
          //   }
          this.$confirm("是否执行此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.classVisible = false;
                  that.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  that.loadAllClass();
                })
                .catch((err) => {
                  that.$message.error(err.message);
                  that.classVisible = false;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }
      });
    },
    modifyClass(grade, cls) {
      this.classVisible = true;
      this.$nextTick(() => {
        this.classForm.id = cls.id;
        this.classForm.gname = grade.gname;
        this.classForm.cname = cls.cname;
        this.classForm.sort_index = grade.sort_index;
        this.classForm.headmanager = {
          name: cls.headmanager,
          id: cls.managerid,
        };
        this.classForm.smanager = cls.smanagerid;
        this.classForm.deputyHeadmanager = cls.f_managerid;
      });
    },
    modifyGradeLeader(grade) {
      this.gradeLeaderVisible = true;
      this.gradeLeaderForm.name = grade.name;
      this.gradeLeaderForm.managerid = grade.managerid;
      this.gradeLeaderForm.id = grade.id;
    },
    gradeLeaderConfirm() {
      const that = this;
      let data = {
        id: this.gradeLeaderForm.id,
        gmanagerid: this.gradeLeaderForm.managerid,
      };
      this.$confirm("是否修改年级长", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/updateGrade.do", data)
            .then((res) => {
              that.$message({
                type: "success",
                message: "修改成功!",
              });
              that.gradeLeaderVisible = false;
              that.loadAllClass();
            })
            .catch((err) => {
              that.gradeLeaderVisible = false;
              that.$message.error(err.message.data);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    furtherEducation() {
      const that = this;
      this.$confirm("是否确认所有班级一键升学", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/upgradeYear.do", {})
            .then((res) => {
              that.$message({
                type: "success",
                message: "操作成功!",
              });
              that.loadAllClass();
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    querySemesterChange(value) {
      this.queryYearId = value;
      this.loadAllClass();
    },
  },
};
</script>

<style scoped lang="scss">
.grade-box {
  //   display: flex;
  //   justify-content: space-between;
  align-items: center;
  background-color: #3ea671;
  font-size: 18px;
  color: #fff;
  padding: 10px 20px;
}
.grade-box > div {
  display: flex;
}
.grade-box > div > div {
  margin-right: 25px;
}
/deep/ .el-link.el-link--default {
  color: #fff;
}
.class-box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.furtherEducation-box {
  margin-bottom: 20px;
}

.class-box > div {
  border-radius: 10px;
  width: 200px;
  background-color: #ffffff;
  margin: 10px 20px 10px 0;
  padding: 10px 15px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  line-height: 11px;
  cursor: pointer;
  transition: all 200ms;
  font-size: 14px;
  position: relative;
  bottom: 0;
}

.class-box > div:hover {
  bottom: 5px;
  background-color: rgb(241, 253, 239);
}

.class-box > div > p:nth-child(1) {
  font-size: 18px;
}
.header {
  display: flex;
  justify-content: space-between;
}
.cls-name {
  font-weight: 700;
}
.cls-name > span {
  margin-right: 5px;
}
</style>
